var SetupPayPostingPayments = function (config) {

    var selected = [];
    var except = [];
    var statuses = {};

    var pjax = $(config.pjax);
    pjax.on('pjax:success', initEvents);

    initEvents();

    function initEvents() {
        var gridId = config.grid;
        var $grid = $(gridId);
        var checkAll = gridId + " input[name='selection_all']";
        var inputs = "input[name='selection[]']";
        var inputsEnabled = gridId + " " + inputs + ":enabled";

        $(document).off('click.yiiGridView', checkAll).on('click.yiiGridView', checkAll, function () {
            if (this.checked) {
                selectAll();
            } else {
                deselectAll();
            }

            $grid.find(inputs + ":enabled").prop('checked', this.checked);
        });

        $(document).off('click.yiiGridView', inputsEnabled).on('click.yiiGridView', inputsEnabled, function (event) {
            if (event.target.checked) {
                select($(event.target).val());
            } else {
                deselect($(event.target).val());
            }

            var all = $grid.find(inputs).length === $grid.find(inputs + ":checked").length && except.length === 0;
            $grid.find("input[name='selection_all']").prop('checked', all);
        });

        if (selected === 'all') {
            pjax.find('[name="selection[]"]').each(function (i, e) {
                if (except.indexOf(e.value) === -1 && !e.checked) {
                    e.click();
                }
            });
        }

        pjax.find('[name="status"]').change(function (event) {
            setStatus($(event.target).closest('tr').find('[name="selection[]"]').val(), $(event.target).val());
        });

        pjax.find('[data-approve-selected]').click(saveAll('approved'));
        pjax.find('[data-refund-selected]').click(saveAll('refunded'));
        pjax.find('[data-save-selected]').click(saveAll());
        pjax.find('[data-save-single]').click(saveSingle);
    }

    function saveSingle() {
        disableButtons();

        var selectValue = $(this).closest('tr').find('td > select').val();
        var selectId = $(this).closest('tr').find('td > [name="selection[]"]').val();

        var status = {};

        status[selectId] = selectValue;

        return $.post(config.postUrl, {
            selected: [selectId],
            statuses: status
        }, function (response) {
            if (response.success) {
                $(config.grid).yiiGridView('applyFilter');
            }
        });
    }

    function saveAll(statusForAll) {
        return function () {
            disableButtons();

            var data = getData();

            return $.post(config.postUrl, {
                selected: data.selected,
                statusForAll: statusForAll || '',
                except: data.except,
                filters: config.filters,
                statuses: data.statuses
            }, function (response) {
                if (response.success) {
                    $(config.grid).yiiGridView('applyFilter');
                }
            });
        }
    }

    function selectAll() {
        selected = 'all';
        except = [];
    }

    function deselectAll() {
        selected = [];
        except = [];
        statuses = {};
    }

    function select(id) {
        if (selected === 'all') {
            var index = except.indexOf(id);
            if (index !== -1) {
                except.splice(index, 1);
            }
            return;
        }
        selected.push(id);
    }

    function deselect(id) {
        if (selected === 'all') {
            except.push(id);
            return;
        }

        var index = selected.indexOf(id);
        if (index !== -1) {
            selected.splice(index, 1);
        }
    }

    function setStatus(id, status) {
        statuses[id] = status;
    }

    function getData() {
        var resultStatuses = {};
        for (var itemId in statuses) {
            if ((selected === 'all' || selected.indexOf(itemId) !== -1) && except.indexOf(itemId) === -1) {
                resultStatuses[itemId] = statuses[itemId];
            }
        }

        return {
            selected: selected,
            except: except,
            statuses: resultStatuses
        };
    }

    function disableButtons() {
        pjax.find('[data-approve-selected]').prop('disabled', true);
        pjax.find('[data-refund-selected]').prop('disabled', true);
        pjax.find('[data-save-selected]').prop('disabled', true);
        pjax.find('[data-save-single]').prop('disabled', true);
    }
};