/**
 * Created by Srdjan on 7/25/2016.
 */
var survey = {}; // namespace

survey.ui = (function ($) {
    return {
        setSurveyTaken : function () {
            localStorage.isSurveyTaken = 1;
        },

        unsetSurveyTaken : function () {
            localStorage.removeItem('isSurveyTaken');
        },
        
        initSurveyButton : function (wrapper, authKey, surveyIdd) {
            
                if(localStorage.isSurveyTaken) {
                    $(wrapper).remove();
                }
                $('#take-survey').click(function(e) {
                    var csrf = '&' + yii.getCsrfParam() + '=' + yii.getCsrfToken();
                    $.get('/survey/finish-survey?key=' + authKey + '&surveyId=' + surveyIdd + csrf);
                    survey.ui.setSurveyTaken();
                    $(wrapper).remove();
                });
        }
    };
})(jQuery);