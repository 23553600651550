let SetupExportReport = function (config) {

    let pjax = $(config.pjax);
    pjax.on('pjax:success', initEvents);

    initEvents();

    function initEvents() {
        pjax.find('[data-handle-action]').click(archiveSingle());
    }

    function archiveSingle() {
        return function () {
            return $.post(config.postUrl, {
                selected: [$(this).data('value')],
            }, function (response) {
                if (response.success) {
                    ems.ui.notify('Payment export request successfully archived.', 'success');
                    $(config.grid).yiiGridView('applyFilter');
                } else {
                    ems.ui.notify('Something went wrong, payment export request can\'t be archived.', 'error');
                }
            });
        }
    }
};