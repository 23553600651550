/* 
 * @author nikola.radovic <dzona065@gmail.com>
 * 
 * Date: 3/25/14
 * Time: 07:48 PM
 */
var ems = {}; // namespace
var summaryID = 'active-form-summary';
var staticSummaryID = 'static-summary-alert';

$ = jQuery.noConflict();

$(function () {

    ems.ui.init();

});

/**
 * ems namespace for helper declarative methods
 *
 * User: tonydspaniard <amigo.cobos@gmail.com>
 */

ems.ui = (function ($) {

    return {
        modal: null,
        hiddenLoadingIcon: '<i class="icon ion-load-c ion-spin icon-hidden"></i>',
        buttonLoadingText: '<i class="icon ion-load-c ion-spin"></i> Loading...',
        buttonSavingText: '<i class="icon ion-load-c ion-spin"></i> Saving...',
        defaultConfirmMessage: 'Do you want to cancel this operation?',
        messageDuration: 6000,
        /**
         * Pushes flash messages
         * @param msg
         * @param type
         * @param duration
         */
        flashMessage: function (msg, type, duration) {
            var cssClass = '',
                closeBtn = '<button type="button" class="close" data-dismiss="alert"><span aria-hidden="true">&times;</span><span class="sr-only">Close</span></button>';
            type = type || 'info';
            duration = duration || ems.ui.messageDuration;
            switch (type) {
                case 'info':
                    cssClass = 'alert-info';
                    break;
                case 'warning':
                    cssClass = 'alert-warning';
                    break;
                case 'error':
                    duration = 0;
                    cssClass = 'alert-danger';
                    break;
                case 'success':
                    cssClass = 'alert-success';
                    break;
            }
            $('#' + summaryID)
                .attr('class', '')
                .addClass('alert ' + cssClass)
                .html(closeBtn + msg)
                .show();
            if (duration) {
                setTimeout(function () {
                    $('#' + summaryID).fadeOut();
                }, duration);
            }
            ems.ui.scrollToFlashMessage();
        },
        notify: function (msg, type, duration, title) {

            type = type || 'info';
            duration = duration || 6000;
            var icon = 'info-sign';
            var gritterTitle = typeof title != "undefined" ? title : type;
            var className = 'gritter-info';

            switch (type) {
                case 'info':
                    icon = 'information-circled';
                    break;
                case 'warning':
                    icon = 'alert-circled';
                    className = 'gritter-warning';
                    break;
                case 'error':
                    type = 'danger';
                    icon = 'close-circled';
                    className = 'gritter-danger';
                    break;
                case 'success':
                    icon = 'checkmark-circled';
                    break;
            }

            $.gritter.add({
                title: '<i class="icon ion-' + icon + '"></i> ' + gritterTitle,
                text: msg,
                class_name: className,
                time: duration
            });
            return this;
        },
        /**
         * array of multiple notifiers
         * @param data
         */
        multiple_notify: function (data) {
            $.each(data, function () {
                ems.ui.notify(this.message, this.type);
            });
            return this;
        },
        controlDropDownPopulate: function () {
            var self = $(this),
                dest = $(self.data('destination'));

            self.attr('disabled', true);

            $.ajax({
                url: self.data('url'),
                type: 'post',
                data: {id: self.val()},
                dataType: 'html',
                success: function (data) {
                    dest.html(data);
                    self.attr('disabled', false);
                },
                error: function (XHR) {
                    ems.ui.notify('Ooops, something went wrong populating dropdown...', 'error');
                    self.attr('disabled', false);
                }
            });

        },
        /**
         * created to attach url redirection functionality to certain buttons
         */
        controlUrl: function () {
            var that = $(this);
            if (that.hasClass('disabled')) {
                return false;
            }
            var url = that.prop('href') || that.attr('data-href');
            if (!url) {
                return false;
            }
            var confirm = that.attr('data-confirm');
            if (confirm) {
                ems.ui.confirm(confirm, function (response) {
                    if (response) {
                        document.location.href = url;
                    }
                    return response;
                });
            }
            document.location.href = url;
            return true;
        },
        /**
         * Handles a form submission via ajax call through meta-data and updates views (grids | lists) if specified
         * @return {*}
         */
        controlAjaxSubmit: function () {
            var self = $(this);
            if (!self.data('loading-text')) {
                self.data('loading-text', ems.ui.buttonSavingText);
            }
            if (self.hasClass('disabled')) {
                return false;
            }

            self.button('loading');

            var frm = this.form ? $(this.form) : self.data('form') ? $('#' + self.data('form')) : self.closest('form');

            if (self.attr('data-confirm')) {
                ems.ui.confirm(confirm, function (result) {
                    if (result) {
                        ems.yiiactiveform.validate(frm, self);
                    }
                });
                return false;
            }

            ems.yiiactiveform.validate(frm, self);
            return false;
        },
        /**
         * Handles a form submission via ajax call through meta-data and updates views (grids | lists) if specified
         * @return {*}
         */
        controlGridAjaxAction: function () {
            var self = $(this), data = {},
                gridId = self.data('grid'),
                confirmMsg = self.attr('data-confirm-msg'),
                promptMsg = self.attr('data-prompt-msg'),
                idHolder = self.attr('data-id-holder');
            if (self.hasClass('disabled')) {
                return false;
            }

            var url = self.prop('href') || self.attr('data-href');
            if (!url) {
                return false;
            }

            if (idHolder) {
                var id = $(idHolder).val();
                if (!id) {
                    alert('You must select something first!');
                    return false;
                }
                self.data('id', id);
            }

            $.each(self.data(), function (key, value) {
                data[key] = value;
            });

            var isConfirm = confirmMsg ? confirm(confirmMsg) : true,
                promptData = promptMsg ? prompt(promptMsg) : false,
                isPrompt = promptMsg ? promptData : true,
                isOk = isConfirm && isPrompt;

            if (promptData) {
                data['prompt'] = promptData;
            }

            if (isOk) {
                $.ajax({
                    url: url,
                    type: 'get',
                    data: data,
                    dataType: 'json',
                    success: function (data) {
                        if (data.success === 'success') {
                            $.pjax.reload({container: '#' + gridId});
                        }
                        ems.ui.notify(data.message, data.success);
                    },
                    error: function (XHR) {
                        ems.ui.notify(XHR.responseText, 'error');
                    }
                });
            }
            return false;
        },
        /**
         * displays a modal witha url
         */
        modalControl: function (e) {
            e.preventDefault();
            var self = $(this);
            if (!self.data('loading-text')) {
                self.data('loading-text', ems.ui.buttonLoadingText);
            }
            if (self.hasClass('disabled')) {
                return false;
            }
            var url = self.attr('data-href') || self.attr('href');
            var data = self.data('params') || '';
            var options = {backdrop: 'static'};
            if (self.attr('data-height')) {
                options.height = self.attr('data-height');
                if (ems.ui.modal.data('modal')) {
                    ems.ui.modal.data('modal').options.height = self.attr('data-height');
                }
            }
            if (self.attr('data-width')) {
                options.width = self.attr('data-width');
                if (ems.ui.modal.data('modal')) {
                    ems.ui.modal.data('modal').options.width = self.attr('data-width');
                }
            }

            setTimeout(function () {
                var modalContent = ems.ui.modal.find('.modal-content');
                modalContent.load(url, data, function () {
                    ems.ui.modal.modal(options);
                });
            }, 200);
            return false;
        },
        openModal: function (content, title, options) {
            var modalContent = ems.ui.modal.find('.modal-body');
            var modalTitle = ems.ui.modal.find('.modal-header');

            var defaults = {
                height: 300,
                width: 600,
                backdrop: 'static',
                keyboard: false,
                closeButton: '<button class="close" aria-hidden="true" data-dismiss="modal" type="button">×</button>'
            };

            options = $.extend(defaults, options);

            modalTitle.html(options.closeButton + title);
            modalContent.html(content);

            ems.ui.modal.modal(options);

            if (options.backdrop == 'static') {
                ems.ui.modal.on('hide.bs.modal', function(e) {
                    e.preventDefault();
                });
            }
        },
        /**
         * closes a modal
         */
        modalClose: function () {
            ems.ui.modal.modal('hide');
        },
        /**
         * submits a form within a modal
         * @return {Boolean}
         */
        modalControlSubmit: function (e) {
            e.preventDefault();
            var self = $(this);
            if (self.hasClass('disabled')) {
                return false;
            }
            var frm = this.form ? $(this.form) : self.data('form') ? $('#' + self.data('form')) : self.closest('form');

            $.ajax({
                url: frm.attr('action'),
                type: 'post',
                data: frm.serialize(),
                dataType: 'json',
                success: function (data) {
                    ems.ui.modal.modal('hide');
                    if (self.attr('data-callback')) {
                        executeFunctionByName(self.attr('data-callback'), window);
                    }
                    if (typeof data == 'object' && data.message) {
                        ems.ui.notify(data.message, data.success);
                    }
                    if (typeof data == 'object' && data.redirectUrl) {
                        window.location.replace(data.redirectUrl); //we are simulationg HTTP redirect
                        ems.ui.notify('We are redirecting you. Please wait...', 'success');
                    }
                },
                error: function (XHR) {
                    ems.ui.modal.find('.modal-content').html(XHR.responseText);
                }
            });
            return false;
        },
        /**
         * Submits a form. Form id is declarative specified on the button  "data-form". This way, we can
         * place the submit button wherever we wish.
         */
        controlSubmit: function (e) {
            var self = $(this);
            if (!self.data('loading-text')) {
                self.data('loading-text', ems.ui.buttonSavingText);
            }
            if (self.hasClass('disabled')) {
                return false;
            }
            var frm = this.form ? $(this.form) : self.data('form') ? $('#' + self.data('form')) : self.closest('form'),
                error = frm.find('.has-error:visible:first');

            if (frm && frm.length) {
                if (self.attr('type') !== 'submit') {
                    if (error.length) {
                        ems.ui.flashMessage('Please fix errors before submitting.', 'error');
                        return false;
                    }
                    frm.submit();
                }
                return true;
            }
            ems.ui.notify('Unable to get a form reference!', 'error');
            return false;
        },
        /**
         * Displays confirmation alert box before proceeding with adding items to work bag
         */
        controlConfirm: function (e) {
            var self = $(this);
            if (self.hasClass('disabled')) {
                return false;
            }
            var msg = self.attr('data-msg') || ems.ui.defaultConfirmMessage;
            var url = self.prop('href') || self.attr('data-url');
            ems.ui.confirm(msg, function (result) {
                if (url && result) {
                    var isJsonResponse = (self.data('json-response') == 1);

                    if(isJsonResponse) {
                        var method = self.data('method') || 'POST';
                        var gridId = self.data('grid') || null;

                        ajaxRequest(method, gridId, url);
                    } else {
                        document.location.href = url;
                    }

                    return true;
                }
            });
            return false;
        },
        /**
         * toggle element visibility
         */
        controlDisplayToggles: function () {
            var self = $(this);
            $('.' + $(this).attr('data-toggle-display')).toggle();

            if (self.attr('data-callback')) {
                executeFunctionByName(self.attr('data-callback'), window);
            }
        },
        /**
         * toggle elements with checkbox
         */
        controlCheckboxToggles: function () {
            var self = $(this),
                $el = $('.' + $(this).attr('data-toggle'));

            var checked = $(this).attr('data-invert') ? !this.checked : this.checked;
            checked ? $el.fadeIn() : $el.fadeOut();

            if (self.attr('data-callback')) {
                executeFunctionByName(self.attr('data-callback'), window);
            }
        },
        /**
         * toggle elements with dropdown
         */
        controlDropdownToggles: function () {
            var self = $(this),
                $el = $('.' + self.attr('data-toggle'));

            if (self.find('option:selected').val() === self.attr('data-trigger-key')) {
                $el.fadeIn();
                if (self.attr('data-callback')) {
                    executeFunctionByName(self.attr('data-callback'), window);
                }
                return;
            }
            $el.fadeOut();
            if (self.attr('data-callback')) {
                executeFunctionByName(self.attr('data-callback'), window);
            }
        },
        /**
         * scroll to first error bow or form element with error
         */
        scrollToError: function () {
            var error = $('body').find('.alert-danger:visible:first');
            error = error.length ? error : $('body').find('.has-error:visible:first');
            if (error.length) {
                $('html, body').animate({
                    scrollTop: error.offset().top
                }, 500);
            }
        },
        /**
         * scroll to first alert box
         */
        scrollToFlashMessage: function () {
            var alert = $('body').find('.alert:visible:first');

            if (alert.length) {
                $('html, body').animate({
                    scrollTop: alert.offset().top
                }, 500);
            }
        },
        /**
         * Bootbox alert wrapper
         *
         * @param string message
         * @param function callback
         */
        alert: function (message, callback) {
            bootbox.alert(message, callback);
        },
        /**
         * Bootbox confirm wrapper
         *
         * @param string message
         * @param function callback
         */
        confirm: function (message, callback) {
            bootbox.confirm(message, callback);
        },
        pasteFromClipboard: function () {
            $($(this).data('target')).val(window.clipboardData.getData('Text'));
        },
        /**
         * Adds / remove spinner overlay
         * @param bool state
         */
        spin: function (state) {
            var spinnerId = 'loading_spinner',
                spinner = '<div id="' + spinnerId + '" class="spinner" style="display:none;"> <div class="spinner-container"><i class="fa-solid fa-spinner"></i></div></div>';
            if (state) {
                $('body').append(spinner);
                $('#' + spinnerId).show();
            } else {
                $('#' + spinnerId).remove();
            }
        },
        ajaxLoadingStart: function () {
            $('.item-ajax-update').addClass('ajax-loading');
        },
        ajaxLoadingFinished: function () {
            $('.item-ajax-update').removeClass('ajax-loading');
        },
        initButtonSpinners: function () {
            $(':submit').click(function (e) {
                $(this).addClass('submittedButton');
            }).prepend(ems.ui.hiddenLoadingIcon);

            $('form').on('beforeSubmit', function (e) {
                var submitButton = $(".submittedButton:submit");
                var showSpinner = submitButton.data('no-spinner') == '1';

                if (e.result == false) {
                    submitButton.find('.icon').addClass("icon-hidden").removeClass('submittedButton');
                    return false;
                }

                !showSpinner && submitButton.find('.icon').removeClass("icon-hidden");

                return true;
            });

        },
        yiiConfirm: function (message, ok, cancel) {
            ems.ui.confirm(message, function (result) {
                if (result) {
                    !ok || ok();
                    return;
                }

                !cancel || cancel();
            });
        },
        formatCurrency: function (amount, currencySign) {
            currencySign = typeof currencySign != "undefined" ? currencySign : "$";

            var textAmount = amount.toFixed(2).split(".");
            return currencySign + " " + textAmount[0].split("").reverse().reduce(reduceArray, "") + "." + textAmount[1];

            function reduceArray(accumulator, currentValue, index) {
                var separator = index && !(index % 3) ? "," : "";

                return currentValue == "-" ? accumulator : currentValue + separator + accumulator;
            }
        },
        /**
         * module init
         */
        init: function () {
            $.extend($.gritter.options, {
                fade_in_speed: 500,
                fade_out_speed: 500,
                time: ems.ui.messageDuration,
                tpl_close: '<a class="gritter-close" href="#" tabindex="1"></a>'
            });

            // widget toggle expand
            $('.widget .btn-toggle-expand').clickToggle(
                function (e) {
                    e.preventDefault();
                    $(this).closest('.widget').find('.slimScrollDiv').css('height', 'auto');
                    $(this).closest('.widget').find('.widget-content').slideUp(300);
                    $(this).find('i').removeClass('ion-ios-arrow-up').addClass('ion-ios-arrow-down');
                },
                function (e) {
                    e.preventDefault();
                    $(this).closest('.widget').find('.widget-content').slideDown(300);
                    $(this).find('i').removeClass('ion-ios-arrow-down').addClass('ion-ios-arrow-up');
                }
            );

            if ($('.validate-card-number').length) {
                $('.validate-card-number').validateCreditCard(function (result) {
                    var validClass = result.valid ? 'valid' : '',
                        cardClass = result.card_type === null ? '' : result.card_type.name;
                    $(this).removeClass().addClass(validClass + ' form-control validate-card-number ' + cardClass);
                });
            }

            // get ems.ui.modal layer
            ems.ui.modal = $('#ems-modal');

            ems.ui.initButtonSpinners();

            // Remove static summary if present
            if ($('#' + staticSummaryID).is(':visible')) {
                setTimeout(function () {
                    $('#' + staticSummaryID).fadeOut();
                }, ems.ui.messageDuration);
            }

            // declarative controls (to be modified in the future to yiiwheels and with a better approach)
            $(document)
                .on('click', '.btn-control-url', this.controlUrl)
                .on('click', '.btn-control-ajax-submit', this.controlAjaxSubmit)
                .on('click', '.btn-modal-control-close', this.modalClose)
                .on('click', '.btn-modal-control', this.modalControl)
                .on('click', '.btn-modal-control-submit', this.modalControlSubmit)
                .on('click', '.btn-control-submit', this.controlSubmit)
                .on('click', '.btn-control-grid-ajax-action', this.controlGridAjaxAction)
                .on('click', '.btn-control-confirm', this.controlConfirm)
                .on('change', 'input:checkbox[data-toggle-display]', this.controlDisplayToggles)
                .on('change', 'select[data-toggle]', this.controlDropdownToggles)
                .on('change', 'input:checkbox[data-toggle]', this.controlCheckboxToggles)
                .on('change', '.dd-control-update', this.controlDropDownPopulate)
                .bind('ajaxStart', this.ajaxLoadingStart)
                .bind('ajaxStop', this.ajaxLoadingFinished);

            yii.confirm = ems.ui.yiiConfirm;

            if (typeof window.clipboardData !== 'undefined') {
                $(document).on('click', '*[data-clipboard-button]', this.pasteFromClipboard);
            } else {
                $('*[data-clipboard-button]').hide();
            }

        }
    };
})(jQuery);

ems.xhr = {
    isJsonResponse: function (xhr) {
        var ct = xhr.getResponseHeader("content-type") || "";
        return ct.indexOf('json') > -1;
    },
    isHtmlResponse: function (xhr) {
        var ct = xhr.getResponseHeader("content-type") || "";
        return ct.indexOf('html') > -1;
    }
};

ems.yiiactiveform = (function ($) {
    return {
        submit: function ($form, $trigger) {
            var url = $form.attr('action');
            $.ajax({
                url: url,
                data: $form.serialize(),
                type: 'post',
                dataType: 'json',
                success: function (data) {
                    ems.ui.flashMessage(data.message, data.type);
                    var reset = $trigger.attr('data-reset');
                    if (reset) {
                        $form[0].reset();
                    }
                    $trigger.button('reset');
                    if ($trigger.attr('data-callback')) {
                        executeFunctionByName($trigger.attr('data-callback'), window);
                    }
                },
                error: function (xhr) {
                    ems.ui.flashMessage(xhr.responseText, 'error');
                    $trigger.button('reset');
                    var reset = $trigger.attr('data-reset');
                    if (reset) {
                        $form[0].reset();
                    }
                }
            });
        },
        validate: function ($form, $trigger) {
            var settings = $.fn.yiiactiveform.getSettings($form);
            if ($trigger.length === 0) {
                $trigger = $("div[class$='-submit'],div[class*='-submit ']");
            }
            if (!settings.validateOnSubmit) {
                return ems.yiiactiveform.submit($form, $trigger);
            }
            settings.submitting = true;
            $.fn.yiiactiveform.validate($form, function (messages) {
                if ($.isEmptyObject(messages)) {
                    $.each(settings.attributes, function () {
                        $.fn.yiiactiveform.updateInput(this, messages, $form);
                    });
                    ems.yiiactiveform.submit($form, $trigger);
                    return true;
                } else {
                    settings = $.fn.yiiactiveform.getSettings($form);
                    $.each(settings.attributes, function () {
                        $.fn.yiiactiveform.updateInput(this, messages, $form);
                    });
                    settings.submitting = false;
                    ems.yiiactiveform.updateSummary($form, messages);
                    ems.ui.scrollToError();
                    $trigger.button('reset');
                    return false;
                }
            });
        },
        updateSummary: function ($form, messages) {
            var settings = $.fn.yiiactiveform.getSettings($form),
                heading = '<p>Please fix the following input errors:</p>',
                list = '';

            $.each(settings.attributes, function () {
                if (messages && $.isArray(messages[this.id])) {
                    $.each(messages[this.id], function (j, message) {
                        list = list + '<li>' + message + '</li>';
                    });
                }
            });
            ems.ui.flashMessage(heading + '<ul>' + list + '</ul>', 'error', 0);
        },
        addAttribute: function ($form, attribute) {
            var settings = $.fn.yiiactiveform.getSettings($form);
            settings.attributes.push(attribute);
            $form.data('settings', settings);
            /*
             * returns the value of the CActiveForm input field
             * performs additional checks to get proper values for checkbox / radiobutton / checkBoxList / radioButtonList
             * @param o object the jQuery object of the input element
             */
            var getAFValue = function (o) {
                var type;
                if (!o.length)
                    return undefined;
                if (o[0].tagName.toLowerCase() == 'span') {
                    var c = [];
                    o.find(':checked').each(function () {
                        c.push(this.value);
                    });
                    return c.join(',');
                }
                type = o.attr('type');
                if (type === 'checkbox' || type === 'radio') {
                    return o.filter(':checked').val();
                } else {
                    return o.val();
                }
            };
            var validate = function (attribute, forceValidate) {
                if (forceValidate) {
                    attribute.status = 2;
                }
                $.each(settings.attributes, function () {
                    if (this.value !== getAFValue($form.find('#' + this.inputID))) {
                        this.status = 2;
                        forceValidate = true;
                    }
                });
                if (!forceValidate) {
                    return;
                }

                if (settings.timer !== undefined) {
                    clearTimeout(settings.timer);
                }
                settings.timer = setTimeout(function () {
                    if (settings.submitting || $form.is(':hidden')) {
                        return;
                    }
                    if (attribute.beforeValidateAttribute === undefined || attribute.beforeValidateAttribute($form, attribute)) {
                        $.each(settings.attributes, function () {
                            if (this.status === 2) {
                                this.status = 3;
                                $.fn.yiiactiveform.getInputContainer(this, $form).addClass(this.validatingCssClass);
                            }
                        });
                        $.fn.yiiactiveform.validate($form, function (data) {
                            var hasError = false;
                            $.each(settings.attributes, function () {
                                if (this.status === 2 || this.status === 3) {
                                    hasError = $.fn.yiiactiveform.updateInput(this, data, $form) || hasError;
                                }
                            });
                            if (attribute.afterValidateAttribute !== undefined) {
                                attribute.afterValidateAttribute($form, attribute, data, hasError);
                            }
                        });
                    }
                }, attribute.validationDelay);
            };
            if (attribute.validateOnChange) {
                $form.find('#' + attribute.inputID).change(function () {
                    validate(attribute, false);
                }).blur(function () {
                    if (attribute.status !== 2 && attribute.status !== 3) {
                        validate(attribute, !attribute.status);
                    }
                });
            }
            if (attribute.validateOnType) {
                $form.find('#' + attribute.inputID).keyup(function () {
                    if (attribute.value !== getAFValue($(attribute))) {
                        validate(attribute, false);
                    }
                });
            }
        },
        createAttribute: function (model, id, name, options) {
            var defaults = {
                enableAjaxValidation: true,
                errorCssClass: "has-error",
                errorID: id + '_em_',
                hideErrorMessage: false,
                id: id,
                inputContainer: "div.form-group",
                inputID: id,
                model: model,
                name: name,
                status: 1,
                successCssClass: 'has-success',
                validateOnChange: true,
                validateOnType: false,
                validatingCssClass: 'validating',
                validationDelay: 200
            };
            return $.extend({}, defaults, options);
        },
        compareDate: function (value, messages, options) {
            if (options.skipOnEmpty && (value === null || value === undefined || value == [] || value === '')) {
                return;
            }

            var compareValue, valid = true;
            if (options.compareAttribute === undefined) {
                compareValue = options.compareValue;
            } else {
                compareValue = $('#' + options.compareAttribute).val();
            }
            value = Date.parse(value, "MM/DD/YYYY");
            compareValue = Date.parse(compareValue, "MM/DD/YYYY");

            switch (options.operator) {
                case '==':
                    valid = value == compareValue;
                    break;
                case '===':
                    valid = value === compareValue;
                    break;
                case '!=':
                    valid = value != compareValue;
                    break;
                case '!==':
                    valid = value !== compareValue;
                    break;
                case '>':
                    valid = value > compareValue;
                    break;
                case '>=':
                    valid = value >= compareValue;
                    break;
                case '<':
                    valid = value < compareValue;
                    break;
                case '<=':
                    valid = value <= compareValue;
                    break;
                default:
                    valid = false;
                    break;
            }

            if (!valid) {
                messages.push(options.message.replace(/\{value\}/g, value));
            }
        }
    };
})(jQuery);

function executeFunctionByName(functionName, context /*, args */) {
    var args = [].slice.call(arguments).splice(2);
    var namespaces = functionName.split(".");
    var func = namespaces.pop();
    for (var i = 0; i < namespaces.length; i++) {
        context = context[namespaces[i]];
    }
    return context[func].apply(this, args);
}

function ajaxRequest(method, gridId, url) {
    $.ajax({
        url: url,
        type: method,
        success: function(response) {
            if(gridId) {
                $.pjax.reload({container:'#' + gridId});
            }
            ems.ui.notify(response.message, response.success);
        },
        error: function( error ){
            if(error.responseText){
                ems.ui.notify(error.responseText, 'error');
            }
        }
    });
}
